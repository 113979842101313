var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-xl mx-auto"},[(_vm.confirmed)?_c('div',[_vm._v("Ahoj")]):_vm._e(),_c('div',{staticClass:"mx-4"},[_c('h2',{staticClass:"text-3xl text-center text-body-4 my-12 font-medium"},[_vm._v(" Osobní údaje ")]),_c('div',[_c('label',{staticClass:"text-15 text-body-4 mt-12 font-bold"},[_vm._v("Jméno a příjmení*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localUser.nameSurname),expression:"localUser.nameSurname"}],class:[
          !_vm.$v.localUser.nameSurname.required && _vm.submitted
            ? 'border-red-300'
            : '',
          _vm.inputStyle ],attrs:{"type":"text"},domProps:{"value":(_vm.localUser.nameSurname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localUser, "nameSurname", $event.target.value)}}}),(!_vm.$v.localUser.nameSurname.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))]):_vm._e()]),_c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-15 text-body-4 mt-12 font-bold"},[_vm._v("Email*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localUser.email),expression:"localUser.email"}],class:[
          (!_vm.$v.localUser.email.email && _vm.submitted) ||
          (!_vm.$v.localUser.email.required && _vm.submitted)
            ? 'border-red-300'
            : '',
          _vm.inputStyle ],attrs:{"type":"text"},domProps:{"value":(_vm.localUser.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localUser, "email", $event.target.value)}}}),(!_vm.$v.localUser.email.email && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(_vm._s(_vm.$t("ErrorMessages.EmailBadFormat")))]):_vm._e(),(!_vm.$v.localUser.email.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))]):_vm._e()]),_c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-15 text-body-4 mt-12 font-bold"},[_vm._v("Telefon*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localUser.phone),expression:"localUser.phone"}],class:[
          !_vm.$v.localUser.phone.required && _vm.submitted ? 'border-red-300' : '',
          _vm.inputStyle ],domProps:{"value":(_vm.localUser.phone)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.localUser, "phone", $event.target.value)},function($event){return _vm.plusHandler()}]}}),(!_vm.$v.localUser.phone.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))]):_vm._e()]),_vm._m(0),_c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-15 text-body-4 font-bold"},[_vm._v("Ulice")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localUser.ulice),expression:"localUser.ulice"}],class:[
          !_vm.$v.localUser.ulice.required && _vm.submitted ? 'border-red-300' : '',
          _vm.inputStyle ],attrs:{"type":"text"},domProps:{"value":(_vm.localUser.ulice)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localUser, "ulice", $event.target.value)}}}),(!_vm.$v.localUser.ulice.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))]):_vm._e()]),_c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-15 text-body-4 font-bold"},[_vm._v("Číslo popisné")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"},{name:"model",rawName:"v-model",value:(_vm.localUser.cislo_popisne),expression:"localUser.cislo_popisne"}],class:[
          !_vm.$v.localUser.cislo_popisne.required && _vm.submitted
            ? 'border-red-300'
            : '',
          _vm.inputStyle ],attrs:{"type":"number"},domProps:{"value":(_vm.localUser.cislo_popisne)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localUser, "cislo_popisne", $event.target.value)}}}),(!_vm.$v.localUser.cislo_popisne.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))]):_vm._e()]),_c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-15 text-body-4 font-bold"},[_vm._v("Město")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localUser.mesto),expression:"localUser.mesto"}],class:[
          !_vm.$v.localUser.mesto.required && _vm.submitted ? 'border-red-300' : '',
          _vm.inputStyle ],attrs:{"type":"text"},domProps:{"value":(_vm.localUser.mesto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localUser, "mesto", $event.target.value)}}}),(!_vm.$v.localUser.mesto.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))]):_vm._e()]),_c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-15 text-body-4 font-bold"},[_vm._v("PSČ")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"},{name:"model",rawName:"v-model",value:(_vm.localUser.psc),expression:"localUser.psc"}],class:[
          !_vm.$v.localUser.psc.required && _vm.submitted ? 'border-red-300' : '',
          _vm.inputStyle ],attrs:{"type":"number","placeholder":"Vyplňte Vaši adresu"},domProps:{"value":(_vm.localUser.psc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localUser, "psc", $event.target.value)}}}),(!_vm.$v.localUser.psc.required && _vm.submitted)?_c('label',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(_vm._s(_vm.$t("ErrorMessages.FieldIsRequired")))]):_vm._e()]),_c('div',{staticClass:"flex justify-center"},[_c('button',{staticClass:"mt-12 text-15 text-white font-bold px-12 py-4 rounded-md bg-body-3 hover:opacity-80 duration-300 focus:outline-none mb-16",on:{"click":function($event){return _vm.save()}}},[_vm._v(" Uložit ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10"},[_c('label',{staticClass:"text-xl text-body-4 font-bold"},[_vm._v("Vaše adresa")])])}]

export { render, staticRenderFns }