<template>
  <div class="max-w-xl mx-auto">
    <div v-if="confirmed">Ahoj</div>
    <div class="mx-4">
      <h2 class="text-3xl text-center text-body-4 my-12 font-medium">
        Osobní údaje
      </h2>
      <div>
        <label class="text-15 text-body-4 mt-12 font-bold"
          >Jméno a příjmení*</label
        >
        <input
          :class="[
            !$v.localUser.nameSurname.required && submitted
              ? 'border-red-300'
              : '',
            inputStyle,
          ]"
          type="text"
          v-model="localUser.nameSurname"
        />
        <label
          v-if="!$v.localUser.nameSurname.required && submitted"
          class="font-medium text-sm text-red-500"
          >{{ $t("ErrorMessages.FieldIsRequired") }}</label
        >
      </div>
      <div class="mt-10">
        <label class="text-15 text-body-4 mt-12 font-bold">Email*</label>
        <input
          :class="[
            (!$v.localUser.email.email && submitted) ||
            (!$v.localUser.email.required && submitted)
              ? 'border-red-300'
              : '',
            inputStyle,
          ]"
          type="text"
          v-model="localUser.email"
        />
        <label
          v-if="!$v.localUser.email.email && submitted"
          class="font-medium text-sm text-red-500"
          >{{ $t("ErrorMessages.EmailBadFormat") }}</label
        >
        <label
          v-if="!$v.localUser.email.required && submitted"
          class="font-medium text-sm text-red-500"
          >{{ $t("ErrorMessages.FieldIsRequired") }}</label
        >
      </div>
      <div class="mt-10">
        <label class="text-15 text-body-4 mt-12 font-bold">Telefon*</label>
        <input
          :class="[
            !$v.localUser.phone.required && submitted ? 'border-red-300' : '',
            inputStyle,
          ]"
          v-model="localUser.phone"
          @input="plusHandler()"
        />
        <label
          v-if="!$v.localUser.phone.required && submitted"
          class="font-medium text-sm text-red-500"
          >{{ $t("ErrorMessages.FieldIsRequired") }}</label
        >
      </div>
      <div class="mt-10">
        <label class="text-xl text-body-4 font-bold">Vaše adresa</label>
      </div>
      <div class="mt-10">
        <label class="text-15 text-body-4 font-bold">Ulice</label>
        <input
          :class="[
            !$v.localUser.ulice.required && submitted ? 'border-red-300' : '',
            inputStyle,
          ]"
          type="text"
          v-model="localUser.ulice"
        />
        <label
          v-if="!$v.localUser.ulice.required && submitted"
          class="font-medium text-sm text-red-500"
          >{{ $t("ErrorMessages.FieldIsRequired") }}</label
        >
      </div>

      <div class="mt-10">
        <label class="text-15 text-body-4 font-bold">Číslo popisné</label>
        <input
          :class="[
            !$v.localUser.cislo_popisne.required && submitted
              ? 'border-red-300'
              : '',
            inputStyle,
          ]"
          v-mask="'#####'"
          type="number"
          v-model="localUser.cislo_popisne"
        />
        <label
          v-if="!$v.localUser.cislo_popisne.required && submitted"
          class="font-medium text-sm text-red-500"
          >{{ $t("ErrorMessages.FieldIsRequired") }}</label
        >
      </div>

      <div class="mt-10">
        <label class="text-15 text-body-4 font-bold">Město</label>
        <input
          :class="[
            !$v.localUser.mesto.required && submitted ? 'border-red-300' : '',
            inputStyle,
          ]"
          type="text"
          v-model="localUser.mesto"
        />
        <label
          v-if="!$v.localUser.mesto.required && submitted"
          class="font-medium text-sm text-red-500"
          >{{ $t("ErrorMessages.FieldIsRequired") }}</label
        >
      </div>

      <div class="mt-10">
        <label class="text-15 text-body-4 font-bold">PSČ</label>
        <input
          :class="[
            !$v.localUser.psc.required && submitted ? 'border-red-300' : '',
            inputStyle,
          ]"
          v-mask="'#####'"
          type="number"
          placeholder="Vyplňte Vaši adresu"
          v-model="localUser.psc"
        />
        <label
          v-if="!$v.localUser.psc.required && submitted"
          class="font-medium text-sm text-red-500"
          >{{ $t("ErrorMessages.FieldIsRequired") }}</label
        >
      </div>

      <div class="flex justify-center">
        <button
          @click="save()"
          class="mt-12 text-15 text-white font-bold px-12 py-4 rounded-md bg-body-3 hover:opacity-80 duration-300 focus:outline-none mb-16"
        >
          Uložit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { putUserData, getMe } from "../../services/rest/services";
import { mapState } from "vuex";

export default {
  data() {
    return {
      submitted: false,
      confirmed: null,
      localUser: {},
      saved: false,
      codeCantIncludes:
        "abcdefghcijklmnopqrstuvwxyzABCDEFGHCIJKLMNOPQRSTUVWXYZ",
      inputStyle:
        "w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  async mounted() {
    const me = await getMe();
    this.localUser = me.data;
  },
  methods: {
    plusHandler() {
      const letterArray = this.codeCantIncludes.split("");
      letterArray.forEach((letter) => {
        if (this.localUser.phone.includes(letter)) {
          this.localUser.phone = "";
        }
      });
      if (this.localUser.phone.length === 0) {
        this.localUser.phone = "";
      }
      if (this.localUser.phone[0] === "-") {
        this.localUser.phone = "";
      }
      if (this.localUser.phone.includes("--")) {
        this.localUser.phone = "";
      }
      if (this.localUser.phone.includes("++")) {
        this.localUser.phone = "";
      }
      if (
        this.localUser.phone.includes("+") &&
        this.localUser.phone[0] !== "+"
      ) {
        this.localUser.phone = "";
      }
      if (this.localUser.phone.length > 18) {
        this.localUser.phone = "";
      }
    },
    async save() {
      this.confirmed = false;
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return this.$toast("Neúspěšný požadavek", {
            className: ["et-alert, screen-style-warning"],
            horizontalPosition: "center",
            mode: "override",
          });
        } else {
          //Save data
          await putUserData(
            JSON.stringify({
              userId: this.user.id,
              data: this.localUser,
            })
          ).then((i) => {
            if (i.confirmed === true) {
              let getUser = JSON.parse(localStorage.getItem("user"));
              getUser.nameSurname = this.localUser.nameSurname;
              getUser.phone = this.localUser.phone;
              getUser.email = this.localUser.email;
              getUser.ulice = this.localUser.ulice;
              getUser.mesto = this.localUser.mesto;
              getUser.cislo_popisne = this.localUser.cislo_popisne;
              getUser.psc = this.localUser.psc;
              getUser = JSON.stringify(getUser);
              localStorage.setItem("user", getUser);
              this.$store.commit("user/SET_USER", JSON.parse(getUser));
              return this.$toast("Úspěšně uloženo", {
                className: ["et-alert, screen-style-info"],
                horizontalPosition: "center",
                mode: "override",
              });
            }
            return this.$toast("Neúspěšný požadavek", {
              className: ["et-alert, screen-style-warning"],
              horizontalPosition: "center",
              mode: "override",
            });
          });
          this.saved = true;
        }
      } catch (error) {
        return this.$toast("Neúspěšný požadavek", {
          className: ["et-alert, screen-style-warning"],
          horizontalPosition: "center",
          mode: "override",
        });
      }
    },
  },
  validations: {
    localUser: {
      email: { required, email },
      phone: { required },
      nameSurname: { required },
      ulice: { required },
      cislo_popisne: { required },
      psc: { required },
      mesto: { required },
    },
  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.screen-style-info {
  background-color: rgba(38, 102, 220, 0.8);
  position: fixed;
  border-radius: 0;
  padding: 15px;
  min-width: 100%;
  width: 100%;
}
.screen-style-warning {
  background-color: rgba(220, 38, 38, 0.8);
  position: fixed;
  border-radius: 0;
  padding: 15px;
  min-width: 100%;
  width: 100%;
}
</style>
